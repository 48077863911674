import * as mdb from 'mdb-ui-kit';
// import favicon from '../img/logo-hyprint.svg';
import $ from 'jquery';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import hljs from 'highlight.js';

// Swagger
//----------------
$(() => {
  $('.swagger').each((i, s) => {
    const config = $(s).attr('data-src');
    console.log(`Swagger source: ${config}`);
    SwaggerUI({
      url: config,
      domNode: s,
    });
  });
});

// content adaptation
//---------------
$(() => {
  console.log('Loaded...');
  // Highlight JS
  //---------------
  hljs.highlightAll();
});
$('#content img').each((i, img) => {
  console.log(`Add class to image: ${$(img).attr('alt')}`);
  const src = $(img).attr('src');
  if (src.indexOf('float-end') !== -1) {
    $(img).addClass('rounded float-end');
  } else if (src.indexOf('badge') !== -1) {
    $(img).addClass('badge');
  } else {
    $(img).addClass('rounded mx-auto d-block');
  }
  // $(img).addClass('rounded mx-auto d-block');
});
export default {
  mdb,
};
